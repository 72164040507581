// minimal
$brand-primary:      #685D9F;

@import "./variables.scss";

// misc typography
$body-color:           #000;
$headings-font-weight: 100;
$btn-font-weight:      300;
$letter-spacing-base:  -.02em;


// lead
$lead-letter-spacing: $letter-spacing-base;
$lead-font-size:      $font-size-base * 1.5;


// blocks
$block-title-font-size-sm: $font-size-base * 5.5;
$block-title-font-size:    $font-size-base * 3.5;
$block-lead-font-size:  $font-size-base * 1.25;


// navbars
$navbar-toggler-border-color: transparent;
$navbar-toggler-hover-bg:     transparent;
$navbar-border:               transparent;
$navbar-brand-color:          $gray-dark;

$nav-bordered-color:              #000;
$nav-bordered-color-active:       $brand-primary;
$nav-bordered-font-weight:        300;
$nav-bordered-font-weight-active: 700;

$navbar-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#685D9F' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

$input-height-base: 39px;

@import "./components.scss";
